(function() {
    'use strict';

    // ? VARIABLES 
    var cerrado     = true;
    var menuLat     = false;
    var resEnPantalla = 0;

    let heros = [];      

    window.onload = (event) => {

        iniciarClicks();

        //! LLENAR LA INFORMACIÓN DE UN SERVICIO
        const servicioId = getParameterByName('id');            

        if (servicioId !== null) {
            mostrarDetallesServicio(servicioId);
        } else {
            //! LLENANDO EL CONTENEDOR DE LOS SERVICIOS
            llenaServicios(); 
        }

        //! LLENAR LA GALERÍA DE FOTOS
        const div_galeria = document.getElementById('div_galfotos');

        if (div_galeria !== null) {
            llena_galeria(div_galeria); 
        };

        //! LLENAR LA GALERÍA DE VÍDEOS
        const vid_container = document.getElementById('div_galvideos');

        if (vid_container !== null) {
            llena_galvideos(vid_container);            
        };

        //! LLENAR LA GALERÍA DE OTROS TRABAJOS
        const ctes_container = document.getElementById('div_galotros');

        if (ctes_container !== null) {

            llena_galotros(ctes_container); 
            
        };

        //! LLENAR LA SECCIÓN DE AUDIOS DISPONIBLES
        const audios_container = document.getElementById('div_audios');

        if (audios_container !== null) {

            llena_audios(audios_container); 
            
        } else {
            console.log('NO ENCUENTRA EL DIV DE LOS AUDIOS');
            
        };

        //! LLENAR LOS BANNERS DE PUBLICIDAD
        const div_pub = document.getElementById('div_pub');

        if (div_pub !== null) {
            llena_bannerspub(div_pub);             
        };

        //! REPRPDUCCIÓN DE VIDEO
        const $vtnaVideo = $('#ventanaVideo');
        const $iframeVideo = $('#iframeVideo');

        $vtnaVideo.on('hidden.bs.modal', function (event) {
            
            $iframeVideo.attr('src',null);

        }); 

    };

    //! =================================================================================
    //* Función que activa el monitoreo de los clics y el scroll
    //! =================================================================================

    function iniciarClicks() {
            
        document.addEventListener('click', (e) => {
            e.preventDefault();
            clicksWeb(e.target);
        });

        window.addEventListener('scroll', function() {
            navBackground();
        })
        navBackground();

        if (document.getElementById("fondosHero")) {
            heros = document.querySelectorAll('.fondo_hero');
            setInterval(next_fondo, 3000); 
        }

    }

    //! =================================================================================
    //* Función que ejecuta los diferentes clicks de la página
    //! =================================================================================

    function clicksWeb(objetoDom) {

        // console.log(objetoDom);

        if (objetoDom.hasAttribute('idclick')) {

            const valorIdClick = objetoDom.getAttribute('idclick');
            const valorIdServicio = objetoDom.getAttribute('idservicio');

            fetch('../../assets/file_clicks.json')
            .then(response => response.json())
            .then(data => {
      
                let lista_clicks = data;

                // Buscar el registro con tipo="pagina" y clave="01"
                let reg_click = data.clicks.find(function(item) {
                    return item.idclick === valorIdClick;
                });

                let tipo_click = reg_click.tipo;
                switch ( tipo_click ) {

                    case "link":

                        let url = reg_click.href;

                        if (!url) {
                            url = objetoDom.href
                            console.log(url);
                        
                        }

                        if (valorIdServicio !== null) {
                            url += "?id=" + valorIdServicio
                        }

                        window.open(url,reg_click.ventana);
                        break;

                    case "function":

                        let idfunc = reg_click.idfunc;

                        switch (idfunc) {

                            case "01":
                                subir_al_top();
                                break;

                            case "02":
                                reproduce_video(objetoDom);
                                break;
                            
                            case "03":
                                activarGaleria(0);
                                break;

                            case "04":
                                activarGaleria(1);
                                break;

                            case "05":
                                activarGaleria(2);
                                break;
                                
                            case "06":
                                toggleAccordion(objetoDom);
                                break;
                            
                            case "07":
                                menuLat = true;
                                apertura();
                                break;

                            case "08":
                                linkToPub(objetoDom);
                                break;
        
                            default:
                                break;
                        }
                        break;    

                    default:
                        break;
                }

          
            })
            .catch(error => {
                console.log('Error al cargar el archivo JSON:', error);
            });
        }
        

    } 

    //! =================================================================================
    //* Función para llenar la galería de fotos a partir de un archivo JSON
    //! =================================================================================

    function llena_galeria(div_galeria) {

        // El elemento sec_galeria solo debe de estar presente en el INDEX.HTML
        // En la página del PORTAFOLIO.HTML no lo debe de tener.
        // Esto me va a permitir identificar si se está visualizando la galería del portafolio
        // en la página principal, para solo mostrar 10 elementos, o si se está visualizando 
        // en la página portafolio.html, para mostrar todos los elementos.
        
        const secGaleria = document.getElementById('sec_galeria');
        const versolo10 = secGaleria !== null ? true : false;

        div_galeria.innerHTML = '';
    
        fetch('../../assets/galeria.json')
            .then(response => response.json())
            .then(data => {
                
                const lista_fotos = data.fotos;
                let cic = 0;
    
                // Recorre el arreglo en sentido inverso (del último al primer elemento)
                for (let i = lista_fotos.length - 1; i >= 0; i--) {
    
                    const foto = lista_fotos[i];
                    const link_foto = document.createElement('div');
    
                    link_foto.innerHTML = `
                        <a href="build/img/galeria/${foto.foto}.jpg" data-lightbox='imagenes'ta-title="Click a la derecha para cambiar a la siguiente imagen.">
                            <picture>
                                <source srcset="build/img/galeria/${foto.foto}.avif" type="image/avif">
                                <source srcset="build/img/galeria/${foto.foto}.webp" type="image/webp">
                                <img loading="lazy" width="450" height="450" src="build/img/galeria/${foto.foto}.jpg" alt="Fotos de la galería">
                            </picture>    
                        </a>
                   `;
    
                   // Agrega la foto al contenedor 
                    div_galeria.appendChild(link_foto);

                    cic++;
                    if (versolo10 === true && cic === 12) {
                        break;    
                    }
                }
            })
        .catch(error => {
            console.error('Error al obtener los datos del archivo JSON:', error);
        });                      
    
    }
    
    //! =================================================================================
    //* Función para llenar la galería de videos a partir de un archivo JSON
    //! =================================================================================

    function llena_galvideos(vid_container) {

        // El elemento sec_galeria solo debe de estar presente en el INDEX.HTML
        // En la página del PORTAFOLIO.HTML no lo debe de tener.
        // Esto me va a permitir identificar si se está visualizando la galería del portafolio
        // en la página principal, para solo mostrar 10 elementos, o si se está visualizando 
        // en la página portafolio.html, para mostrar todos los elementos.
        
        const secGaleria = document.getElementById('sec_galeria');
        const versolo10 = secGaleria !== null ? true : false;

        vid_container.innerHTML = '';
    
        fetch('../../assets/videos_pagina.json')
            .then(response => response.json())
            .then(data => {
                
                const lista_vidgaleria = data.videos;
                let cic = 0;
    
                // Recorre el arreglo en sentido inverso (del último al primer elemento)
                for (let i = lista_vidgaleria.length - 1; i >= 0; i--) {
    
                    const video = lista_vidgaleria[i];
    
                    if (video.tipo === 'galeria') {
    
                        const video_gal = document.createElement('div');
                        video_gal.classList.add('position-relative', 'gal_video');
    
                        video_gal.innerHTML = `
                            <picture>
                                <source srcset="../../build/img/videos/${video.clave}.avif" type="image/avif">
                                <source srcset="../../build/img/videos/${video.clave}.webp" type="image/webp">
                                <img class="img-fluid shadow rounded" loading="lazy" width="150" height="150" src="../../build/img/videos/${video.clave}.jpg" alt="Fotos de la galería">
                            </picture>    
    
                            <div class="playGalery">
                                <a href="#myModal"><img width="100" height="100" src="build/img/play.png" alt="Dale PLAY al vídeo" class="play" clave="${video.clave}" idclick="008" tipo="galeria"></a>
                            </div>
                        `;
    
                        // Agrega la tarjeta al contenedor de productos
                        vid_container.appendChild(video_gal);

                        cic++;
                        if (versolo10 === true && cic === 12) {
                            break;    
                        }
    
                    }
                }
            })
        .catch(error => {
            console.error('Error al obtener los datos del archivo JSON:', error);
        });                      
    }
    
    //! =================================================================================
    //* Función para llenar la galería de clientes a partir de un archivo JSON
    //! =================================================================================

    function llena_galotros(ctes_container) {

        ctes_container.innerHTML = '';

        fetch('../../assets/galotros.json')
            .then(response => response.json())
            .then(data => {
                
                const lista_ctes = data.clientes;

                lista_ctes.forEach(cte => {

                    const div_cliente = document.createElement('div');
                    div_cliente.classList.add('card_cte');

                    div_cliente.innerHTML = `
                        <div class="cte_portada">
                            <picture>
                                <source srcset="build/img/galotros/${cte.idcte}.avif" type="image/avif">
                                <source srcset="build/img/galotros/${cte.idcte}.webp" type="image/webp">
                                <img class="img-fluid shadow rounded" loading="lazy" width="150" height="150" src="build/img/galotros/${cte.idcte}.jpg" alt="Fotos de la galería">
                            </picture>        
                        </div>
                        <div class="cte_logo">
                            <picture>
                                <source srcset="build/img/galotros/logo${cte.idcte}.avif" type="image/avif">
                                <source srcset="build/img/galotros/logo${cte.idcte}.webp" type="image/webp">
                                <img class="img-fluid shadow rounded" loading="lazy" width="150" height="150" src="build/img/galotros/logo${cte.idcte}.jpg" alt="Fotos de la galería">
                            </picture>        
                        </div>
                        <div class="cte_info">
                            <h4>${cte.nomcte}</h4>
                            <p>${cte.descripcion}</p>
                        </div>
                        <div class="cte_contacto">
                            <i class="fas fa-desktop iconos" idclick="${cte.idclick_web}"></i>
                            <i class="fab fa-facebook iconos" idclick="${cte.idclick_fb}"></i>
                        </div>    
                    `;

                    // Agrega la tarjeta al contenedor de productos
                    ctes_container.appendChild(div_cliente);

                });
            })
        .catch(error => {
            console.error('Error al obtener los datos del archivo JSON:', error);
        });                      

    }

    //! =================================================================================
    //* Función para llenar la sección de audios a partir de un archivo JSON
    //! =================================================================================

    function llena_audios(audios_container) {

        audios_container.innerHTML = '';

        fetch('../../assets/audios.json')
            .then(response => response.json())
            .then(data => {
                
                const lista_audios = data.audios;

                lista_audios.forEach(audio => {

                    const card_audio = document.createElement('div');
                    card_audio.classList.add('audio-card');

                    card_audio.innerHTML = `

                        <h5>${audio.nombre}</h5>
                        <audio controls> 
                            <source src="build/audios/${audio.audio}.m4a" type="audio/mp3">
                            Tu navegador no soporta el elemento de audio M4A.
                        </audio>
                    `;

                    // Agrega la tarjeta al contenedor
                    audios_container.appendChild(card_audio);

                });
            })
        .catch(error => {
            console.error('Error al obtener los datos del archivo JSON:', error);
        });                      

    }

    //! =================================================================================
    //* Función para llenar el slider de los banners de publicidad
    //! =================================================================================

    function llena_bannerspub(div_pub) { 

        let divIndicadores = document.getElementById('div_indicadores');

        div_pub.innerHTML = '';
        divIndicadores.innerHTML = '';

        fetch('../../assets/patros.json')
            .then(response => response.json())
            .then(data => {
                                    
                const anuncios = data.anuncios;
                let i = 0;

                anuncios.forEach(anuncio => {

                    // ! Creando los botones de los indicadores del carrusel
                    let button = document.createElement("button");
                    button.setAttribute("type", "button");
                    button.setAttribute("data-bs-target", "#crs_pub");
                    button.setAttribute("data-bs-slide-to", i.toString());                    

                    const link_anuncio = document.createElement('div');

                    link_anuncio.classList.add('carousel-item');
                    if (i===0) {
                        button.classList.add("active");                            
                        link_anuncio.classList.add('active');
                    }
                    i++;

                    link_anuncio.innerHTML = `

                        <a href="${anuncio.href}">
                            <picture>
                                <source srcset="build/img/folderpub/${anuncio.clave}.avif" type="image/avif">
                                <source srcset="build/img/folderpub/${anuncio.clave}.webp" type="image/webp">
                                <img src="build/img/folderpub/${anuncio.clave}.jpg" alt="Banner publicitario" idclick="019" class="d-block fto-img-pub" style="width:100%">
                            </picture>    
                        </a>

                    `;

                    // Agrega la anuncio al contenedor 
                    divIndicadores.appendChild(button);                       
                    div_pub.appendChild(link_anuncio);
                });
            })
        .catch(error => {
            console.error('Error al obtener los datos del archivo JSON:', error);
        });                      
    
    }

    //! =================================================================================
    //* Función para subir hasta el TOP de la página
    //! =================================================================================
    
    function subir_al_top() {        
        window.scrollTo({top: 0, behavior: 'smooth'});        
    }

    //! =================================================================================
    //* Función para visualizar el tipo de galería que se escogió en pantalla: 
    //* Fotos, Vídeos u Otros
    //! =================================================================================

    function activarGaleria(galeria) {

        let botones = document.querySelectorAll('.botgalery');
        let galeriaSel = document.querySelectorAll('.galeria_content');

        for (let i = 0; i < botones.length; i++) {

            if (i === galeria) {
                botones[i].classList.remove('botonGris')
                botones[i].classList.add('boton')
                galeriaSel[i].classList.add('galactive');
            } else {
                botones[i].classList.remove('boton')
                botones[i].classList.add('botonGris')
                galeriaSel[i].classList.remove('galactive');
            }
           
        }

    }    

    //! =================================================================================
    //* Función para abrir el menú lateral cuando el tamaño sea tablet o inferior
    //! =================================================================================

    function apertura() {
        let menu    = document.getElementById('navPrincipal');
        let botSubir = document.getElementById('botInicio');
        let botAccWP	 = document.getElementById('botWP');
        
        if (cerrado) {
            menu.style.width = '100vw!important';
            cerrado = false;
            
            if (botSubir) {
                botSubir.style.visibility = 'hidden';
                botAccWP.style.visibility = 'hidden';
            }

        } else {
            menu.style.width = '0%';
            menu.style.overflow = 'hidden';
            cerrado = true;
            menuLat = false;
            if (botSubir) {
                botSubir.style.visibility = 'visible';
                botAccWP.style.visibility = 'visible';
            }
        }
        menu.classList.toggle('menu2');
    };   

    //! =================================================================================
    //* Función para activar el efecto de las prefuntas frecuentes
    //! =================================================================================

    function toggleAccordion(elem) {

        let elemFaq;
        let i;

        if (elem.matches('.btnFaq') === false) {
            elemFaq = elem.parentNode;
        } else {
            elemFaq = elem;
        }

        const items = document.querySelectorAll(".accordion button");
        const itemToggle = elemFaq.getAttribute('aria-expanded');
        
        for (i = 0; i < items.length; i++) {
          items[i].setAttribute('aria-expanded', 'false');
        }
        
        if (itemToggle == 'false') {
          elemFaq.setAttribute('aria-expanded', 'true');
        }
    }
    
    //! =================================================================================
    //* Función para cambiar el color de fondo de la barra de menú según la posición de la pantalla
    //* Si está en el top, es transparente, si no se le pone un color obscuro
    //! =================================================================================

    function navBackground() {

        let nav = document.querySelector('.nav');
        let desp_actual = window.pageYOffset;
        let ancho_screen = window.innerWidth;        

        if ( desp_actual <= 300 ) {

            nav.style.borderBottom = ''; 

            if (ancho_screen > 768 ) {
                nav.style.background = 'rgba(0,0,0,0.5)';
            } else {
                nav.style.background = 'linear-gradient(0deg, rgba(0,0,0,0) 5%, rgba(0,0,0,1) 100%)';
            }

        } else {
            nav.style.background = '#1F2022';
            nav.style.borderBottom = '2px solid #E33803';
        }
        nav.style.transition = '.5s';
    }

    function next_fondo() {
        let idxHero = actBanner(heros, 0);      
        nextBanner(heros, idxHero, '+');        
    }

    function actBanner(array, idxBan) {
        while (idxBan < array.length) {
            if (array[idxBan].classList.contains('banner_visible')) {
                break;
            }
            idxBan++;
        }
        return idxBan;
    }

    function nextBanner(array, idx, opr) {
        let idxNext = idx;
        if (opr==='+') {
            idxNext++;
            if (idxNext > array.length - 1) {
                idxNext = 0;
            }    
        } else {
            --idxNext;
            if (idxNext < 0) {
                idxNext = array.length - 1;
            }
        }
        array[idx].classList.remove('banner_visible');
        array[idxNext].classList.add('banner_visible');
    }

    //! =================================================================================
    //* Functión para reproducir un vídeo
    //! =================================================================================

    function reproduce_video( obj ) {

        // Cargando la lista de videos
        let tipo = $(obj).attr('tipo');
        let clave = $(obj).attr('clave');
            
        fetch('../../assets/videos_pagina.json')
            .then(response => response.json())
            .then(data => {
        
                let lista_videos = data;

                const $modTitulo = $('#mod-titulo');
                const $modDesc = $('#mod-desc');        

                // Buscar el registro con tipo="pagina" y clave="01"
                var video = data.videos.find(function(item) {
                    return item.tipo === tipo && item.clave === clave;
                });

                $modTitulo.text(video.titulo);
                $modDesc.text(video.descrip);
                
                $iframeVideo.attr('src',video.link+"?autoplay=1");

                $vtnaVideo.modal('show');
            
            })
        .catch(error => {
            console.log('Error al cargar el archivo JSON:', error);
        });

    }

    //! =================================================================================
    //* Functión para llenar el contenedor de los servicios.
    //* 1) Si la función recibe un nombre de categoría, entonces muestra solo los servicios de esa categoría
    //* 2) Si no recibe categoría, entonces valida si se deben de mostrar solo los servicios de la pantalla principal
    //* 3) Si no está el atributo servicios-principales, entonces visualizará todos los servicios.
    //! =================================================================================
    
    function llenaServicios(idcategoria = '') {

        const secServicios = document.getElementById('sec-servicios');

        if (secServicios !== null) {        

            // Verificar si existe el elemento y contiene la clase "servicios-principales"
            const mostrarSoloPrincipales = secServicios.classList.contains('servicios-principales');
            // console.log(secServicios);            
            // console.log(mostrarSoloPrincipales); 
            
            // Leer el archivo servicios.json
            fetch('../../assets/servicios.json')
                .then(response => response.json())
                .then(data => {

                    const servicios = data.servicios;
                    secServicios.innerHTML = ''; // Limpiar el contenedor de servicios

                    servicios.forEach(servicio => {

                        // Verificar si se deben mostrar solo los servicios principales
                        if (mostrarSoloPrincipales && servicio.paginaprincipal !== 'true') {
                            return; // Saltar al siguiente servicio
                        }

                        // Verificar si se proporcionó un valor para idcategoria
                        if (idcategoria && servicio.categoria !== idcategoria) {
                            return; // Saltar al siguiente servicio
                        }

                        // Crear el HTML del servicio y agregarlo al contenedor
                        const servicioHTML = `
                            <div class="box"> 
                                <div class="box_image">
                                    <picture>
                                        <source srcset="build/img/servicios/servicio_${servicio.idservicio}.avif" type="image/avif">
                                        <source srcset="build/img/servicios/servicio_${servicio.idservicio}.webp" type="image/webp">
                                        <img src="build/img/servicios/servicio_${servicio.idservicio}.webp" class="img-fluid rounded-top imagen-servicio" alt="Imagen del servicio" idclick="${servicio.idclick}" idservicio="${servicio.idservicio}">
                                    </picture>                                    
                                </div>
                                <div class="box_content"> 
                                    <div class="box_texto">
                                        <a href="#" class="text-dark"><h5 idclick="${servicio.idclick}" idservicio="${servicio.idservicio}">${servicio.titulo}</h5></a>
                                        <p class="text-muted">${servicio.dcorta}</p>
                                    </div>
                                    <div class="box_footer"> 
                                        <span>${servicio.precio}</span>
                                        <a href="#" title="Ver detalles" data-bs-toggle="popover" data-bs-trigger="focus" data-bs-content="Próximamente disponible."><i class="fas fa-info" idclick="${servicio.idclick}" idservicio="${servicio.idservicio}"></i></a>
                                    </div>
                                </div>
                            </div>
                        `;

                        secServicios.innerHTML += servicioHTML;
                    });
                })
            .catch(error => {
                console.error('Error al obtener los servicios del archivo JSON:', error);
            });
        }
    }

    //! =================================================================================
    //* Función para obtener el valor del parámetro "id" desde la URL
    //! =================================================================================

    function getParameterByName(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }

    //! =================================================================================
    //* Función para cargar y mostrar los detalles del servicio desde el archivo JSON
    //! =================================================================================

    function mostrarDetallesServicio(id) {

        fetch('../../assets/servicios.json') 
            .then(response => response.json())
            .then(data => {

                // Buscar el servicio por su ID en el archivo JSON
                const datosServicio = data.servicios.find(servicio => servicio.idservicio === id);

                if (datosServicio) {

                    // Si se encontró el servicio, mostrar los detalles 
                    // ${datosServicio.titulo}
                    // ${datosServicio.descripcion}

                    // ! Visualizando la imagen de fondo del servicio
                    const fondoHeroServ = document.getElementById('fondoServicio');
                    if (fondoHeroServ !== null) {
                        fondoHeroServ.innerHTML = `
                            <picture>
                                <source srcset="build/img/servicios/fs_${id}.avif" type="image/avif">
                                <source srcset="build/img/servicios/fs_${id}.webp" type="image/webp">
                                <img width="350" height="150" src="build/img/servicios/fs_${id}.jpg" alt="Fondo de Pantalla TEXTOS DE IMPACTO">
                            </picture>
                        `;
                    }

                    // ! Visualizando los datos del encabezado                    
                    const secTitulo = document.getElementById('secTituloH1');
                    if (secTitulo !== null) {
                        secTitulo.innerHTML = `
                            <h1>${datosServicio.titulo}</h1>
                            <p>${datosServicio.dcorta}</p>
                
                            <div class="precio-servicio">
                                <p class="precio">${datosServicio.precio} mx</p>
                                <p class="notas-precio"><i class="fas fa-info-circle"></i> ${datosServicio.notasprecio}</p>
                                <a href="${datosServicio.linkpago}" idclick="032" class="boton">Comprar</a>
                                <a id="video-servicio" href="#" class="boton" idclick="008" clave="${datosServicio.video}" tipo="pagina"><i class="fas fa-play"></i> Ver video</a>
                            </div>
                        `;
                    }

                    // ! Visualizando los detalles del servicio
                    const secDetalles = document.getElementById('detalle-servicio');
                    if (secDetalles !== null) {
                        secDetalles.innerHTML = `
                            <h2>${datosServicio.titulo}</h2>
                            <p>${datosServicio.dlarga}</p>
                            <p class="notas-servicio"><i class="far fa-clock"></i> ${datosServicio.tiempo}, dependiendo de la hora en la que se solicite el servicio.</p>
                            <p class="notas-servicio" id="notas"><i class="fas fa-hand-point-right"></i> ${datosServicio.notas}</p>
                        `;
                    }

                    // ! Visualizando el precio del servicio
                    const secPrecio = document.getElementById('precio-servicio');
                    if (secPrecio !== null) {
                        secPrecio.innerHTML = `
                            <p class="precio">${datosServicio.precio} mx</p>
                            <p class="notas-precio"><i class="fas fa-info-circle"></i> ${datosServicio.notasprecio}</p>
                            <a href="${datosServicio.linkpago}" idclick="032" class="boton">Comprar</a>
                        `;
                    }

                    // ! Ocultando las notas de los precios cuando el valor de notasprecio es vacío
                    if (!datosServicio.notasprecio) {
                        // Seleccionar ambos elementos con la clase "notas-precio"
                        const elementos = document.querySelectorAll('.notas-precio');
                        
                        // Recorrer los elementos y asignar la clase para hacerlos invisbles
                        elementos.forEach(elemento => {
                            elemento.classList.add('nomostrar');
                        });
                    }
                      
                    // ! Ocultando las notas del servicio cuando el valor de notas es vacío
                    if (!datosServicio.notas) {
                        const notas = document.getElementById('notas');
                        notas.classList.add('nomostrar');
                    }
                    
                    // ! Ocultando el botón del vídeo cuando el servicio no tiene vídeo
                    if (!datosServicio.video) {
                        const videoServicio = document.getElementById('video-servicio');
                        videoServicio.classList.add('nomostrar');
                    }

                    // ! Verificando si el servicio tiene sección exclusiva y si es así, visualizando la sección en pantalla
                    if (datosServicio.bloque) {
                        const idBloque = datosServicio.bloque;
                        const bloqueServicio = document.getElementById(idBloque);
                        bloqueServicio.classList.remove('nomostrar');
                    }                    

                    //! Visualizando los servicios relacionados de la misma categoría
                    llenaServicios(datosServicio.categoria); 


                } else {
                    
                    console.log('El servicio seleccionado no existe: ' + id);
                    console.log(datosServicio);
                    
                }
            })
        .catch(error => {
            console.error('Error al obtener los datos del archivo JSON:', error);
        });
    }

    //! =================================================================================
    //* Abre el enlace de uno de los banners de publicidad
    //! =================================================================================

    function linkToPub(obj) {
        
        const hrefValue = obj.parentNode.parentNode.getAttribute('href');
        window.open(hrefValue, '_blank');

    }



})();